import { 
    EXAM_GENERATION, 
    EXAM_GENERATOR, 
    ACTIVITY, 
    ACTIVITIES_LIST, 
    RANDON_ACTIVITY, 
    UNIT_BY_ISBN_URI, 
    SINGLE_EXAM_GENERATOR 
} from "../CONSTANTS";

import { mockup } from "./mockup";

export const fetchExamGenerators = () => {
    // return Promise.resolve(mockup)

    return fetch(EXAM_GENERATOR, {
        method: 'GET'
    }).then(data => {
        return data.json();
    }).catch(error => {
        console.error('error', error)
        return error;
    });
}

export const deleteGenerator = (id) => {
    return fetch(`${EXAM_GENERATOR}/${id}?physical=1`, {
        method: 'DELETE'
    }).then(res => {
        return res
    }).catch(error => {
        console.error('error', error)
        return error;
    })
}

export const createExamGenerator = (data) => {
    return fetch(EXAM_GENERATOR, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
            // Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    }).then(data => {
        return data.json();
    }).catch(error => {
        console.error('error', error)
        return error;
    });
}

export const editExamGenerator = (data) => {
    return fetch(`${EXAM_GENERATOR}/${data.id}`, {
        method: 'PUT',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
            // Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    }).then(data => {
        return data.json();
    }).catch(error => {
        console.error('error', error)
        return Promise.reject(error)
    });
}

export const generateExam = async ({ isbn, numQuestions, title, units, lang = '', oc_type = 'all' }) => {
    const body = {
        'title': title,
        'creator_id': 1,
        'lang': lang,
        'num_questions': +numQuestions,
        'criteria': {
            isbn: isbn,
            units: units,
            oc_type: oc_type
        }
    };
    const data = await fetch(`${EXAM_GENERATION}`, {
        method: 'POST',
        body: JSON.stringify(body)
    })

    return await data.json();
}

export const getActivity = async (id) => {

    const data = await fetch(`${ACTIVITY.replace("{id}", id)}`, {
        method: 'GET'
    })

    return await data.json();
}

export const getActivitiesByIds = async (ids) => {
    const data = await Promise.all(ids.map(getActivity))
    return data
}

export const getActivitiesByIsbnAndUnits = async (isbn, units = [], selectedActivityTypes='all', lang, page) => {
    const newUnitQuery = units.length > 0
        ? '&units=' + units.join(',')
        : ''
    let url = `${ACTIVITIES_LIST}&lang=${lang}&isbn=${isbn}${newUnitQuery}&oc_type=${selectedActivityTypes}` 
    
    // handle pagination
    const ps = 1000 // 25 
    url += `&page=${page ?? 1}&limit=${ps}`
    const data = await fetch(url, { method: 'GET' })
    return await data.json();
}

export const getRandonActivity = async (isbn = "", units = [], lang = "", oc_type = "all", activities = {}) => {
    let uri = RANDON_ACTIVITY.replace("{isbn}", isbn);
    uri = uri.replace("{activities}", activities.join('-'));
    uri = uri.replace("{units}", units);
    uri = uri.replace("{lang}", lang);
    uri = uri.replace("{oc_type}", oc_type);

    const data = await fetch(uri, {
        method: 'GET'
    })
    return await data.json();
}

export const getUnitsByIsbn = async (book, selectedActivityTypes="all") => {
    const data = await fetch(UNIT_BY_ISBN_URI.replace("{isbn}", book.isbn).replace("{lang}", book.language_default).replace("{selectedActivityTypes}", selectedActivityTypes), {
        method: 'GET'
    })
    return await data.json();

}

export const fetchExamGeneratorByIsbn = (isbn) => {
    return fetch(`${SINGLE_EXAM_GENERATOR}/${isbn}`, {
        method: 'GET'
    })
        .then((response) => {
            if (response.status !== 200) throw new Error();

            return response;
        })
        .then(data => {
            return data.json();
        })
}

export const checkExamIsbn = async (isbn) => {
    return fetch(`${EXAM_GENERATOR}/${isbn}/checker`, {
        method: 'GET'
    }).then(response => {
        return response.json();
    })
}
