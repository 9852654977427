import { XOWL_URI } from "../CONSTANTS";
import Axios from "axios";

const axios = Axios.create()

export const getLinkPathDocx = async (formData) => {
    const controller = new AbortController();
    const signal = controller.signal;
    let responseAxios;
    try {
        responseAxios = await axios.post(XOWL_URI + 'document/html2docx', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            signal,
            responseType: 'blob'
        })
        responseAxios ={
            status: responseAxios.status,
            file:  URL.createObjectURL(responseAxios.data)
        }
    } catch (error) {
        console.error('error', error.response.data)
        responseAxios =  error;
    }
    
    return {
        controller,
        response: responseAxios
    }
}
