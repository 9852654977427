import React, { useEffect, useRef, useState } from 'react';
import {
    Document,
    Page,
    View,
    Text
} from '@react-pdf/renderer';
import { ACTIVITIES_OPEN_QUESTION } from './../../CONSTANTS';
import { Html } from 'react-pdf-html';
import styles, { stylesBlock } from './stylesPdfExam';
import { decodeHTML, wrapImagesWithDiv, } from '../../helpers/functionsExam';
import HeaderPdfExam from './HeaderPdfExam';
import FooterPdfExam from './FooterPdfExam';
import ShowCorrectResultsOpenQuestion from './ShowCorrectResultsOpenQuestion';
import ShowActivity from './ShowActivity';
import stylesheetPDF from './StylesheetsPDF';
import './FontRegister';
import ReactDomServer from 'react-dom/server';


const PdfExam = ({showCorrectResults, handleDOC, ...props}) => {
    const examData = props?.content;

    // !!! OJO un require dentro del component???
    const parse = require('html-react-parser');
    const logoMHE = '/images/logo-mhe.png'
    const [htmls, setHtmls] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingChilds, setIsLoadingChilds] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        examData?.activities?.forEach((activity, index) => {
            if (activity.text) {
                let _html = decodeHTML(activity?.text,stylesBlock(true))
                wrapImagesWithDiv(_html,'', true)
                    .then(html => setHtmls(prevState => ({...prevState, [index]: html})))
                    .catch(e => {
                        console.error(e)
                        setHtmls(prevState => ({...prevState, [index]: _html}))
                    })
            }
        })

    }, [examData.activities])

    useEffect(() => {
        if (examData?.activities?.length === Object.keys(htmls).length) {
            // setIsLoading(false)
            handleDOC(examData, showCorrectResults)
        }
    }, [htmls, showCorrectResults])
    
    useEffect(() => {
        // if (!isLoading && !isLoadingChilds) {
        //     setTimeout(() => props.handleLoadPDF(false), 5000)
        // } else {
        //     props.handleLoadPDF(true)
        // }
    }, [isLoading, isLoadingChilds])

    const handleLoadPDFChilds = (value) => {
        setIsLoadingChilds(value)
    }

    const htmlRef = useRef(null)
    window.htmlRef = htmlRef
    window.ReactDomServer = ReactDomServer
    if (!examData) return null;



    window.htmlToString = ` 
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <style>
        /*
            * {
                font-family: 'Calibri';
                font-size: 12px;
            }
        */
        </style>
    </head>
    <body>
            
        ${ReactDomServer.renderToString(
            <div style={{fontFamily: 'Calibri', fontSize:12}} ref={htmlRef}>
                <>
                    <img alt="logo-mhe" src={'https://exam-generator.medu.ximdex.net/'+logoMHE} style={styles.logoMhe} fixed="true" />
                    {examData?.title && (
                        <p style={styles.header} fixed="true">
                            {examData?.title}
                        </p>
                    )}
                    <div style={styles.table_header}>
                        <div style={styles.row_header}>
                            <p style={styles.cell_header}>{examData.name}</p>
                            <p style={styles.cell_header}>{examData.surname}</p>
                        </div>
                        <div style={styles.row_header}>
                            <p style={styles.cell_header}>{examData.date}</p>
                            <p style={styles.cell_header}>{examData.course}</p>
                        </div>
                    </div>
                </>

                <div 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            marginTop: 20,
                            width: '100%',
                            maxHeight: '100%',
                            boxsizing: 'border-box'
                        }}
                    >
                        {examData?.activities?.map((activity, index) => {
                            return (
                                <div 
                                    key={'examData_activity' + index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 5,
                                    }}
                                >
                                    {activity?.title && <p style={styles.question}>{index + 1}) {parse(activity?.title)}</p>}
                                    {htmls[index] && (
                                        <div dangerouslySetInnerHTML={{__html: htmls[index]}}></div>)
                                    }
                                    <div style={{maxWidth: '100%', width: '100%'}}>
                                        {ACTIVITIES_OPEN_QUESTION.includes(activity.type) 
                                            ? (
                                                <div key={'open_answer_' + activity.id} style={{...styles.answer}}>
                                                    <p>✅</p>
                                                        <div style={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            display: 'initial'
                                                        }}>
                                                            <div dangerouslySetInnerHTML={{__html: decodeHTML(activity.targets[0].accepted_values.join(' '), `<style>p{font-family: 'Calibri';font-size:12px;}</style>` + stylesBlock(true), true)}} ></div>
                                                        </div>
                                                </div>
                                            ) :  activity?.options?.map((option, indexOption) => (
                                                <div
                                                    key={'options' + indexOption}
                                                    style={{
                                                        ...styles.options,
                                                        gap: 5,
                                                        alignItems: 'flex-start',
                                                    }}
                                                >

                                                    {option?.title && showCorrectResults && (
                                                        <p style={{fontSize: 12}}>
                                                            {option?.is_right?.toUpperCase() === 'TRUE' ? '✅' : '❌'}
                                                        </p>
                                                    )}
                                                    <p style={{fontSize: 12}}>
                                                        {(indexOption + 10).toString(36) + ') '}
                                                    </p>
                                                    
                                                    <div dangerouslySetInnerHTML={{__html: decodeHTML(option.title, stylesBlock(true), true)}}></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>

            </div>
        )}
    </body>
    </html>
    
`
           
    
    return (
        <>
        <Document
            onRender={() => {
                // console.log('renderizado')
                props.handleLoadPDF(false)
            }}
            author='Mcgraw-Hill Education'
            title={examData.title}
            pageLayout="singlePage" 
            producer='Ximdex'
            creator="Ximdex - Exam-Generator"
        >
            <Page style={styles.body}  
                size={'A4'}
                orientation='portrait'
            >
                <HeaderPdfExam examData={examData} logo={logoMHE}/>
                <View 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        marginTop: 20,
                        width: '100%',
                        maxHeight: '100%',
                        boxsizing: 'border-box'
                    }}
                >
                    {examData?.activities?.map((activity, index) => {
                        return (
                            <View 
                                key={'examData_activity' + index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                }}
                            >
                                {activity?.title && <Text style={styles.question}>{index + 1}) {parse(activity?.title)}</Text>}
                                {htmls[index] && (
                                    <Html stylesheet={stylesheetPDF}
                                    >{htmls[index]}</Html>)
                                }
                                <View style={{maxWidth: '100%', width: '100%'}}>
                                    {ACTIVITIES_OPEN_QUESTION.includes(activity.type) 
                                        ? (
                                            <ShowCorrectResultsOpenQuestion 
                                                isPDF 
                                                handleLoadPDF={handleLoadPDFChilds} 
                                                activity={activity} 
                                                showCorrectResults={showCorrectResults}
                                            />
                                        ) :  activity?.options?.map((option, indexOption) => (
                                            <ShowActivity 
                                                isPDF 
                                                handleLoadPDF={handleLoadPDFChilds} 
                                                activity={activity} 
                                                option={option} 
                                                indexOption={indexOption} 
                                                showCorrectResults={showCorrectResults}
                                            />
                                        ))
                                    }
                                </View>
                            </View>
                        )
                    })}
                </View>
                <FooterPdfExam/>
            </Page>
        </Document>
        </>
    )
}

export default PdfExam;
