import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSpinner } from '@ximdex/xui-react/hooks';
import Exam from '../../components/Exam/Exam';
import useQuery from '../../hooks/useQuery';
import { generateExam, getActivitiesByIds } from '../../services/exam.service';

const ExamView = () => {
    const { isbnUnderscoreHash } = useParams()
    const [isbn, hash] = isbnUnderscoreHash.split('_')
    const [, setSearchParams] = useSearchParams();
    const queryParams = useQuery();
    const lang = queryParams.get('lang') || 'es';
    const units = queryParams.get('units') || '';
    const title = queryParams.get('title') || '';
    const num = queryParams.get('num') || 1;
    const oc_type = queryParams.get('oc_type') || 'all';
    const activitiesId = queryParams.get('activities');
    const [examInfo, setExamInfo] = useState(null);
    const { showSpinner, hideSpinner } = useSpinner();

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        updateUrl()
    }, [examInfo])

    const init = async () => {
        showSpinner()
        if (activitiesId !== null) {
            let activities = []
            if (activitiesId !== '') activities = await getActivitiesByIds(activitiesId.split('-'))
            const data = {
                "language_default": lang,
                "title": title,
                "activities": activities,
                "oc_type": oc_type
            }
            setExamInfo(data)
            hideSpinner()
            return
        }


        showSpinner()
        const data = await generateExam({
            numQuestions: num,
            units: units.replace("-", ","),
            title: title,
            isbn: isbn,
            lang: lang.toUpperCase(),
            oc_type: oc_type
        })
        setExamInfo(data)
        hideSpinner()
    }

    const setActivities = (activities) => {
        setExamInfo({ ...examInfo, activities: activities })
    }

    const updateUrl = () => {
        if (examInfo !== null) {
            let params = {
                lang,
                units,
                title,
                activities: examInfo.activities.map(e => e.id).join('-'),
                oc_type
            }
            setSearchParams(params)
        }
    }

    if (examInfo === null) return null;

    if (!examInfo || examInfo.length === 0) return <h2>Data not found</h2>;

    return (
        <Exam
            isbn={isbn}
            units={units}
            language_default={lang}
            oc_type={oc_type}
            setActivities={setActivities}
            data={examInfo}
            setGeneratedExam={setExamInfo}
        />
    )
}

export default ExamView