import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { XInput, XDropdown, XButton, XBox } from '@ximdex/xui-react/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LANGUAGES, LEGAL_NOTICES } from '../../CONSTANTS';
import { GeneratorContainer, StyledXBox, StyledHelperText } from './StyledGenerator';
import { checkExamIsbn, createExamGenerator, editExamGenerator } from '../../services/exam.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import MessageToUser from '../../components/MessageToUser/MessageToUser';
import { PageBox, PageContainer, PageFooter } from '../../styled-components';
import { PageHeader, PageTitle } from '../../styled-components/Titles';

const initBook = {
    title: '',
    subject: '',
    isbn: '',
    language_default: 'ES', // optional, default='ES'
    level: 1,
    // is_demo: false,
    url: 'https://www.ximdex.com/',
    demo_url: 'https://www.ximdex.com/',
    // permissions: {}, // optional
    credits: '', // optional
    legal_notice: LEGAL_NOTICES.ES, // optional
    version: 1,
    image: '', // optional
    // image: 'https://xdam.mhe.ximdex.net/api/v1/resource/render/@@@dam:@image@93a2c73e-9d21-447b-a285-6d1523a496e5@11f15c80-593f-40a6-9091-571fe5583289@@@/small', // optional sample image
    // status_id: 1 // optional, default=1
}

const ExamGeneratorCreator = () => {
    const { showSpinner, hideSpinner } = useSpinner();
    const [isbnError, setIsbnError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [_t] = useTranslation("createGenerateView");


    const handleInfoState = () => {
        return ((location.state?.edit && location.state?.book) || (location.state?.clone && location.state?.book)) 
            ? location.state?.book
            : initBook
    }

    const [messageToUser, setMessageToUser] = useState(location.state?.messageToUser ?? '')
    const [info, setInfo] = useState(handleInfoState())

    useEffect(()=> {
        setInfo(handleInfoState())
    }, [location.state?.book?.id])

    const handleInputChange = (value, key) => {
        setInfo(infoState => ({ ...infoState, [key]: value }))

        if (key === 'language_default') setInfo(infoState => ({ ...infoState, legal_notice: LEGAL_NOTICES[value] }))
        if(key === 'isbn' && value === "") setIsbnError("")
    }

    const createGenerator = () => {
        let info_to_send = Object.assign({}, info)
        let infoToSend = {}
        Object.keys(info_to_send).forEach(k => {
            infoToSend[k] = (typeof info_to_send[k] == 'string')  ? info_to_send[k].trim() : info_to_send[k]
        })
        showSpinner()
        createExamGenerator(infoToSend).then(data => {
            if (data.isbn) {
                setMessageToUser(_t("messages.success_create_generator"))
                navigate(`/edit-generator/${data.isbn}_${data.hash}`, {state: {book: data, edit: true, messageToUser: _t("messages.success_create")}})
            } else {
                setMessageToUser(_t("messages.fail_create_generator"))
            }
        }).catch(error => {
            console.error('createGenerator: ', error)
            setMessageToUser(_t("messages.fail_create_generator"))
            alert(error);
        }).finally(() => {
            hideSpinner();
        })
    }

    const editGenerator = () => {
        let info_to_send = Object.assign({}, info)
        delete info_to_send.units
        delete info_to_send.language_default
        let infoToSend = {}
        Object.keys(info_to_send).forEach(k => {
            infoToSend[k] = (typeof info_to_send[k] == 'string')  ? info_to_send[k].trim() : info_to_send[k]
        })

        showSpinner()
        editExamGenerator(infoToSend).then(data => {
            if (data?.id) {
                setInfo(data)
                setMessageToUser(_t("messages.success_edit"))
            } else {
                setMessageToUser(_t("messages.fail_edit"))
            }
        }).catch(error => {
            setMessageToUser(_t("messages.fail_edit"))
            alert(error)
        }).finally(() => {
            hideSpinner()
        })
    }

    const checkISBN = (isbn) => {
        if (isbn !== location.state?.book?.isbn) {
            checkExamIsbn(isbn)
            .then(res => {
                setIsbnError( res === "unavailable" ? _t("messages.fail_reason_unavailable") // "We are not able to detect any activities using this ISBN in xEval."
                            : res === "duplicate" ? _t("messages.fail_reason_duplicate") // "Looks like a generator with this same ISBN already exists."
                            : res === "ok" ? "" : ""
                )
            })
            .catch(error => console.error("checkISBN: ", error))
        } else {
            setIsbnError("")
        }
    }

    return (
        <PageContainer>
            <PageBox>
                <PageHeader>
                    <PageTitle>{info?.id ? _t("title_edit_generator"): _t("title_new_generator")}</PageTitle>
                </PageHeader>
                <StyledXBox backgroundImage={info.image ?? ''} >
                    <XInput
                        fullWidth
                        type="text"
                        required
                        label={_t("inputs.title")}
                        value={info.title}
                        onChange={(e) => handleInputChange(e.target.value, 'title', e)}
                    />
                    <XInput
                        fullWidth
                        type="text"
                        required
                        label={_t("inputs.ISBN")}
                        value={info.isbn}
                        onBlur={(e) => checkISBN(e.target.value)}
                        onChange={(e) => handleInputChange(e.target.value, 'isbn')}
                    />
                    {isbnError && <StyledHelperText type='error' style={{marginTop:'-8px'}}>{isbnError}</StyledHelperText>}
                    <XDropdown 
                        options={LANGUAGES}
                        value={LANGUAGES.filter(lang => lang.id === info.language_default)[0]}
                        disabled={location.state?.edit}
                        style={{
                            marginTop: '1rem'
                        }}
                        size='small'
                        required
                        disableClearable
                        multiple={false}
                        label={_t("inputs.language_default")}
                        labelOptions='title'
                        width='100%'
                        onChange={(e, selectedInput) => handleInputChange(selectedInput?.id ?? '', 'language_default')}
                    />
                    {(!location.state?.edit || location.state?.clone) && 
                        <StyledHelperText type='info'>
                            {_t("helper_texts.instructions_generator_language")} {/* Once created you will not be allowed to change the language of this generator. */}
                        </StyledHelperText>
                    }
                    <XInput
                        fullWidth
                        type="text"
                        multiple={false}
                        label={_t("inputs.image")}
                        value={info.image}
                        onChange={(e) => handleInputChange(e.target.value, 'image')}
                    />
                    <XInput
                        fullWidth
                        type="text"
                        multiline
                        minRows='2'
                        maxRows='4'
                        label={_t("inputs.credits")}
                        value={info.credits}
                        onChange={(e) => handleInputChange(e.target.value, 'credits')}
                    />
                    <XInput
                        fullWidth
                        type="text"
                        multiline
                        minRows='2'
                        maxRows='4'
                        label={_t("inputs.legal_notice")}
                        defaultValue={info?.legal_notice}
                        value={info?.legal_notice}
                        onChange={(e) => handleInputChange(e.target.value, 'legal_notice')}
                    />
                </StyledXBox>
                <PageFooter style={{flexDirection: 'column', gap: 0, padding: '0px 0px 15px'}}>
                    
                    <XButton
                        disabled={(isbnError ? true : false) || (!info.isbn || !info.title)}
                        style={{ margin: '1rem', width: '-webkit-fill-available' }}
                        onClick={info?.id ? editGenerator : createGenerator}
                    >
                        {_t(info?.id ? "buttons.edit_generator" : "buttons.create_generator")}
                    </XButton>

                    <XButton
                        style={{ margin: '0 1rem', width: '-webkit-fill-available' }}
                        onClick={() => navigate('/list-generators')}
                        variant="outlined"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size='1x' style={{ marginRight: '8px' }}/> 
                        {_t("buttons.go_back_to_list")}
                    </XButton>

                    {messageToUser &&
                        <MessageToUser 
                            messageToUser={messageToUser} 
                            setMessageToUser={setMessageToUser}
                        />
                    }
                </PageFooter>
            </PageBox>
        </PageContainer>
    )
}

export default ExamGeneratorCreator;
