import { Text } from "@react-pdf/renderer"
import styles from "./stylesPdfExam"

const FooterPdfExam = () => {
    return(
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber}-${totalPages}`
        )} fixed />
    )
    
}

export default FooterPdfExam;