import styled from "styled-components/macro"
import { COLORS_MHE } from "../CONSTANTS"
import { XBox } from "@ximdex/xui-react/material"

export const StyledDivSideBySide = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 16px;
`

export const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 15px;
    background-color: ${COLORS_MHE.light_gray};

`
    
export const PageBox = styled(XBox)`
    margin: 0 !important;
    padding: 0 !important;
    max-width: 1200px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
    border: unset; 
    border-radius: 0 !important;
    background-color: ${COLORS_MHE.white};

    @media only screen and (max-width: 900px ){
        margin-left: 1rem;
        margin-right: 1rem;
    }

`

export const PageContent = styled('div')`
    overflow-y: auto;
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
`

export const PageFooter = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    gap: 16px;
    border-top: 1px solid ${COLORS_MHE.navy_100};
    background-color: ${COLORS_MHE.navy_50};
`
