import { styled } from '@mui/system';
import { PageBox } from '../../styled-components';

export const CoverContainer = styled(PageBox)`
    margin: 8px auto 28px auto;
    padding: 2.5em;
    background-color: #fff;
    border-radius: 8px;
    width: fit-content;

    img {
        height: 100%;
        object-fit: contain;
    }
`
