import { styled } from '@mui/system'
import { XButton } from '@ximdex/xui-react/material'

export const FormStyled = styled('form')`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    background-color: #fff;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const StyledXButton = styled(XButton)``
