import './App.css';
import { LIGHT_THEME } from './CONSTANTS';
import { XThemeProvider } from '@ximdex/xui-react/providers';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import { ExamProvider } from './stores/Exam/ExamProvider';

const App = () => {

    return (
        <BrowserRouter>
            <div className="App">
                <XThemeProvider lightTheme={LIGHT_THEME}>
                    <ExamProvider>
                        <Routes />
                    </ExamProvider>
                </XThemeProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
