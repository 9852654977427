import { styled } from '@mui/system';
import { XCard } from '@ximdex/xui-react/material'
import { COLORS_MHE } from '../../CONSTANTS';

export const StyledXCard = styled(XCard)`
    width: 100%;
    min-width: 400px;
    margin: 1rem auto;
    box-sizing: border-box;
    padding: 0px 4px;
    border-radius: 4px;

    h2 {
        color: ${COLORS_MHE.navy};
        font-size: 1.5rem;
    }

    & button {
        background-color: ${COLORS_MHE.navy};
        width: 30px;
        height: 30px;
        transition: all 0.1s ease !important; 
        
        & svg {
            fill: ${COLORS_MHE.white};
            width: 10px;
        }
        
        &:hover {
            background-color: ${COLORS_MHE.navy_500};
            color: ${COLORS_MHE.red_academic};
            
            & svg {
                fill: ${COLORS_MHE.red_academic};
            }
        }
    }
`

export const ActivityContainer = styled('div')`
    border: 1px solid #ecf1f6;
    border-radius: 8px;
    background-color: #fcfdfe;
    margin: 8px 0;
    padding: 0 24px;
    text-align: left;
    color: #06235b;
`
export const IdParagraph = styled('p')`
    font-weight: bold;
`

export const TitleParagraph = styled('p')`
    margin: 8px 0 0 0;
`

export const TextParagraph = styled(TitleParagraph)`
    display: -webkit-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`
export const ActivitiesContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`
