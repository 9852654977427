import { useEffect, useState } from 'react'
import { getLinkPathDocx } from '../../services/xowl.service'
import getHtmlTemplate from './template'
import { decodeHTML, wrapImagesWithDiv } from '../../helpers/functionsExam'
import { stylesBlock } from '../PdfExam/stylesPdfExam'
import { ACTIVITIES_OPEN_QUESTION } from '../../CONSTANTS'

const formatDOC = true

function useHtml2Docx(title, lang) {
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [controllerAbort, setControllerAbort] = useState(false)
    const [data, setData] = useState(false)

    const [activities, setActivities] = useState({})
    const [options, setOptions] = useState({})
    const [target, setTarget] = useState({})

    const [isLoadings, setIsLoadings] = useState({ activities: false, options: false, target: false, xowlPost: false })

    const [formData, setFormData] = useState(false)

    useEffect(() => {
        let loading = isLoadings.activities || isLoadings.options || isLoadings.target || isLoadings.xowlPost || !formData
        let time = loading 
            ? (isLoadings.xowlPost ? 0 : 2000)
            : 0
            
        let idtimeout =setTimeout(() => {
            if (isLoadings.activities || isLoadings.options || isLoadings.target || isLoadings.xowlPost || !formData) {
                return setLoading(true)
            }
            setLoading(false)

        }, time)

        return () => clearTimeout(idtimeout)
    }, [isLoading, formData])


    useEffect(() => {
        if (controllerAbort) {
            controllerAbort.abort()
        }
        if (data.examData) {
            setIsLoadings({ activities: true, options: true, target: true })
            setFormData(false)

            let promisesHtmls = []
            let promisesOptions = {}
            let promisesTarget = {}
            let _options = {}
            let _target = {}

            data?.examData?.activities?.forEach((activity, index) => {
                if (activity.text) {
                    let _html = decodeHTML(activity?.text, stylesBlock(formatDOC))
                    promisesHtmls.push(wrapImagesWithDiv(_html, '', false, formatDOC))
                }


                if (ACTIVITIES_OPEN_QUESTION.includes(activity?.type)) {

                    let _html = decodeHTML(activity.targets[0].accepted_values.join(' '), `<style>p{font-family: 'Calibri';font-size:12px;}</style>` + stylesBlock(formatDOC), formatDOC)
                    if (!_target[index]) _target[index] = []
                    if (Array.isArray(_html) && _html.length === 0) _html = ''
                    _target[index] = _html;

                    if (!promisesTarget[index]) promisesTarget[index] = []
                    promisesTarget[index] = wrapImagesWithDiv(_html, "", false, formatDOC)

                } else {
                    if (!_options[index]) _options[index] = []
                    if (!promisesOptions[index]) promisesOptions[index] = []
                    activity?.options?.forEach((option, key) => {
                        let htmlOption = decodeHTML(option.title, stylesBlock(formatDOC), formatDOC)
                        if (Array.isArray(htmlOption) && htmlOption.length === 0) htmlOption = ''
                        _options[index][key] = htmlOption;
                        if (!promisesOptions[index][key]) promisesOptions[index][key] = []
                        promisesOptions[index].push(wrapImagesWithDiv(htmlOption, "", false , formatDOC))
                    })
                }

            })
            Promise.allSettled(promisesHtmls)
                .then(output => {
                    let htmls = {}
                    output.forEach((promise, index) => {
                        if (promise.status === 'fulfilled') {
                            htmls[index] = promise.value
                        }
                        if (promise.status === 'rejected') {
                            console.error('rejected', promise.reason)
                            htmls[index] = data?.examData?.activities[index]
                        }
                    })
                    setActivities(htmls)
                    setIsLoadings({ ...isLoadings, activities: false })
                })
                .catch(e => {
                    setError(e.message)
                    console.error(e)

                })

            Object.keys(promisesOptions).forEach((key) => {
                Promise.allSettled(promisesOptions[key])
                    .then(output => {
                        output.forEach((promise, index) => {
                            if (promise.status === 'fulfilled') {
                                if (Array.isArray(promise.value) && promise.value.length === 0) {
                                    // promise.value = ''
                                } else {
                                    _options[key][index] = promise.value
                                }
                            }
                            if (promise.status === 'rejected') {
                                console.error('rejected', promise.reason)
                                let data_option = data.examData.activities[key]?.options[index]
                                if (Array.isArray(data_option) && data_option.length === 0) {
                                    // data_option = ''
                                } else {
                                    _options[key][index] = data_option
                                }
                            }
                        })
                        setIsLoadings({ ...isLoadings, options: false })
                        setOptions(_options)
                    })
                    .catch(e => {
                        setError(e.message)
                        console.error(e)

                    })
            })

            Promise.allSettled(Object.values(promisesTarget))
            .then(output => {
                output.forEach((promise, index) => {
                    if (promise.status === 'fulfilled') {
                        _target[index] = promise.value
                    }
                    if (promise.status === 'rejected') {
                        console.log('rejected', promise.reason)
                    }
                })
                setTarget(_target)
                setIsLoadings({ ...isLoadings, target: false })
            })
            .catch(e => {
                console.error(e)
                setError(e.message)
            })

        }
    }, [data])

    useEffect(() => {
        if (Object.keys(activities).length > 0 && (Object.keys(options).length || Object.keys(target).length) > 0) {

            const { examData, showResults } = { ...data }
            const toTemplate = {
                examData,
                showCorrectResults: showResults,
                htmls: activities,
                options,
                target
            }
            let htmlString = getHtmlTemplate(toTemplate)
            const htmlblob = new Blob([htmlString], { type: 'text/html' })
            const htmlFile = new File([htmlblob], `${examData.title}.html`, { type: 'text/html' });

            let dt = new DataTransfer();
            dt.items.add(htmlFile);

            let inputElement = document.createElement("input");
            inputElement.type = "file";
            inputElement.files = dt.files;

            const formData = {
                lang: lang ?? 'es-ES',
                title: examData.title,
                type: 'exam-mhe',
                file: inputElement.files[0],
                htmlString
            }

            if (!isLoadings.activities && !isLoadings.options && !isLoadings.target) {
                setFormData(formData)
            }
        }

    }, [activities, options, target, isLoadings])

    useEffect(() => {
        const postData = async (formData) => {

            const { controller, response } = await getLinkPathDocx(formData)
            setControllerAbort(controller)

            if (response?.status === 200) {
                const a = document.createElement('a')
                a.href = response.file
                a.download = `${title}.docx`
                a.click()
            }
            if (response.error) {
                setError(error.data)
            }

            setIsLoadings({ ...isLoadings, xowlPost: false })
        }
        if (isLoadings.xowlPost) {
            delete formData.htmlString
            postData(formData)
        }

        return () => controllerAbort?.abort?.()

    }, [isLoadings.xowlPost])

    const getLinkDocx = (examData, showResults) => {
        setError(false)
        setData({ examData, showResults })
    }

    const handleDownload = () => {
        setIsLoadings({ ...isLoadings, xowlPost: true })
    }

    return ({
        loadingDOC: isLoading, 
        error,
        handleDOC: getLinkDocx,
        handleDownload,
        htmlDoc: formData?.htmlString
    })
}

export default useHtml2Docx
