import React from 'react'
import {
    Routes as RouterRoutes,
    Route,
    useLocation,
    useNavigate
} from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'

import { useTranslation } from 'react-i18next';
import { StyledXNav } from '../styled-components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faRectangleList, faBook, faHome } from '@fortawesome/free-solid-svg-icons';

import Login from './../pages/Login/Login';
import Home from './../pages/Home/Home';
import ExamGeneratorCreator from './../pages/ExamGeneratorCreator/ExamGeneratorCreator';
import ExamView from '../pages/ExamView/ExamView';
import PageContainer from '../components/PageContainer/PageContainer';
import GeneratorView from '../pages/GeneratorView/GeneratorView';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

const Routes = () => {
    const location = useLocation();
    const navigation = useNavigate();
    const [_t] = useTranslation("homePage");

    const pathname = location.pathname;
    const isUnprotectedRoute = pathname.split('/')[1] === 'generator' || pathname.split('/')[1] === 'exam-view';
    const isAuth = sessionStorage.getItem('isAuth') && sessionStorage.getItem('isAuth') === 'true';

    const logoLink = {
        logoImgSrc: '/images/logo-mhe.png',
        href: isUnprotectedRoute
            ? `/generator/${pathname.substring(location.pathname.lastIndexOf('/') + 1)}`
            : isAuth
                ? '/list-generators'
                : '/'
    };

    const links = location.pathname === '/'
        ? []
        : isUnprotectedRoute
            ? [
                [
                    {
                        url: `/generator/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`,
                        target: '_self',
                        icon: <FontAwesomeIcon title={_t('cover')} icon={faBook} size="1x" style={{ marginRight: 15 }} />
                    },
                    {
                        url: '/list-generators',
                        target: '_self',
                        icon: <FontAwesomeIcon title={_t('title')} icon={faRectangleList} size="1x" style={{ marginRight: 15 }} />
                    },
                ], 
                [
                    {
                        icon: (
                            <div title="Superadmin" style={{display: 'flex' , flexFlow: 'row nowrap', gap: 5, marginRight: 10, fontSize: 20, alignItems: 'center'}}>
                                <FontAwesomeIcon title="Superadmin" icon={faUser} size="1x" style={{ fontSize: 24 }} />
                            </div>
                        ),
                        items: [
                            {
                                text: 'Superadmin',
                            },
                            {
                                text: 'Sign out',
                                icon: <FontAwesomeIcon icon={faSignOutAlt} size="1x" style={{ marginRight: 8 }} />,
                                onClick: () => {
                                    sessionStorage.clear();
                                    navigation('/');
                                }
                            }
                        ]
                    }
                ],
            ] : [
                [
                    {
                        url: '/list-generators',
                        target: '_self',
                        icon: <FontAwesomeIcon title={_t('title')} icon={faRectangleList} size="1x" style={{ marginRight: 15 }} />
                    },
                ], 
                [
                    {
                        icon: (
                            <div title="Superadmin" style={{display: 'flex' , flexFlow: 'row nowrap', gap: 5, marginRight: 10, fontSize: 20, alignItems: 'center'}}>
                                <FontAwesomeIcon title="Superadmin" icon={faUser} size="1x" style={{ fontSize: 24 }} />
                            </div>
                        ),
                        items: [
                            {
                                text: 'Superadmin'
                            },
                            {
                                text: 'Sign out',
                                icon: <FontAwesomeIcon icon={faSignOutAlt} size="1x" style={{ marginRight: 8 }} title='Sign out' />,
                                onClick: () => {
                                    sessionStorage.clear();
                                    navigation('/');
                                }
                            }
                        ]
                    },
                ]
            ];

    return (
        <>
            <StyledXNav
                color='primary'
                logoLink={logoLink}
                links={links}
            />
            <PageContainer style={{
                overflow: 'hidden',
                width: '100%',
                display: 'flex',
                boxSizing: 'border-box',
                justifyContent: 'center',
                height: '100%'
            }}>
                <RouterRoutes>
                    <Route exact path='/' element={<Login />} />
                    <Route path='/list-generators' element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path='/create-generator' element={<ProtectedRoute><ExamGeneratorCreator /></ProtectedRoute>} />
                    <Route path='/edit-generator/:isbnUnderscoreHash' element={<ProtectedRoute><ExamGeneratorCreator /></ProtectedRoute>} />
                    <Route path='/generator/:isbnUnderscoreHash' element={<GeneratorView />} />
                    <Route path='/exam-view/:isbnUnderscoreHash' element={<ExamView />} />
                    <Route path="*" element={<PageNotFound />} />
                </RouterRoutes>
            </PageContainer>
        </>

    )
}

export default Routes
