const navigatorLngISO639 = window.navigator.language.slice(0, 2)

const availableLngs = ['es', 'en', 'ca', 'eu', 'gl']

const navigatorLang = availableLngs.includes(navigatorLngISO639) ? navigatorLngISO639 : 'es'

const currentLang = sessionStorage.getItem("currentLang")

const es = {
    loginPage: require("./es/loginPage.json"),
    homePage: require("./es/homePage.json"),
    generatorView: require("./es/generatorView.json"),
    createGenerateView: require("./es/createGenerateView.json"),
    examView: require("./es/examView.json"),
}

const en = {
    loginPage: require("./en/loginPage.json"),
    homePage: require("./en/homePage.json"),
    generatorView: require("./en/generatorView.json"),
    createGenerateView: require("./en/createGenerateView.json"),
    examView: require("./en/examView.json"),
}

const ca = {
    loginPage: require("./ca/loginPage.json"),
    homePage: require("./ca/homePage.json"),
    generatorView: require("./ca/generatorView.json"),
    createGenerateView: require("./ca/createGenerateView.json"),
    examView: require("./ca/examView.json"),
}

const eu = {
    loginPage: require("./eu/loginPage.json"),
    homePage: require("./eu/homePage.json"),
    generatorView: require("./eu/generatorView.json"),
    createGenerateView: require("./eu/createGenerateView.json"),
    examView: require("./eu/examView.json"),
}
const gl = {
    loginPage: require("./gl/loginPage.json"),
    homePage: require("./gl/homePage.json"),
    generatorView: require("./gl/generatorView.json"),
    createGenerateView: require("./gl/createGenerateView.json"),
    examView: require("./gl/examView.json"),
}

const getConfigLanguages = () => {
    return {
        interpolation: { escapeValue: false },
        lng: currentLang || navigatorLang,
        resources: {
            es: es,
            en: en,
            ca: ca,
            eu: eu,
            gl: gl
        }
    }
}

export default getConfigLanguages;
