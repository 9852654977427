export const MAX_WIDTH_PDF = 475;

export const decodeHTML = (html, styles, isPDF=false) => {
    if (isPDF && !/<\/?[a-z][\s\S]*>/i.test(html)) {
        html = '<p>' + html + '</p>'
    }

    const content = styles + html.replace(/&([^;]+);/g, (match, entity) => {
        const element = document.createElement('textarea');
        element.innerHTML = `&${entity};`;
        return element.value;
    })

    return content;
};

export const handleHTMLonPDF =  (htmlString) => {
    //* handle fonts on PDF
    const regexFonts = /font-family\s*:\s*([^;]*)(;|'|")/gi;
    const regexFontSizes = /font-size\s*:\s*([^;]*)(;|'|")/gi;
    
    const regexTables = /(<table[\s\S]*?>)/g;
    
    htmlString = htmlString.replace(regexTables, (match, table) => {
        
        const stylesMatch = table.match(/style=(["'])(.*?)\1/);

        const widthRegex = /width\s*:\s*[^;]+;/i;
        let newStyle;
        if (widthRegex.test(stylesMatch[2])) {
            newStyle = stylesMatch[2].replace(widthRegex, "");
        } 
        newStyle += ' max-width: 100%;'
        newStyle =  stylesMatch[0].replace(stylesMatch[2], newStyle)
        
        return table.replace(stylesMatch[0], newStyle);
    });
    htmlString = htmlString.replace(regexFonts, (match, fontFamily) => {
        return `font-family: Calibri;`;
    });
    htmlString = htmlString.replace(regexFontSizes, (match, fontSize) => {
        return `font-size: 12px;`;
    });

    return htmlString
}

const handleHTMLonDOC = (htmlString) => {
    const regexTables = /(<table[\s\S]*?<\/table>)/g;
    htmlString = htmlString.replace(regexTables, (match, table) => `<div custom-style="Body">${table}</div>`);

    // handle images to base64
    // const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    // const contentDocument = convertImagesToBase64(doc)
    // htmlString = `<!DOCTYPE html>${contentDocument.documentElement.outerHTML}`

    return htmlString
}

export const wrapImagesWithDiv = async (htmlString, divStyle = '', isPDF = false, isDOCX = false) => {
    
    if (isDOCX) htmlString = handleHTMLonDOC(htmlString)
    if (isPDF) htmlString = await handleHTMLonPDF(htmlString)
    
    //* handle images
    let imgRegex = /<img([^>]*)>/g;

    let matchRegex;
    let replacedText = htmlString;
    while ((matchRegex = imgRegex.exec(htmlString)) !== null) {
        let aspect_ratio;
        const match = matchRegex[0]
        const attributes = matchRegex[1]
        const widthMatch = attributes.match(/width=(["'])(.*?)\1/);
        const heightMatch = attributes.match(/height=(["'])(.*?)\1/);
        const srcMatch = attributes.match(/src=(["'])(.*?)\1/);
        const stylesMatch = attributes.match(/style=(["'])(.*?)\1/);
        
        let sizes = { 
            width: (widthMatch && widthMatch[2] !== "") ? widthMatch[2] : false, 
            height: (heightMatch && heightMatch[2] !== "") ? heightMatch[2] : false 
        }
        if (srcMatch && (!sizes.width || !sizes.height ||sizes.width === 'auto' || sizes.height === 'auto')) {
            const imgSizes = await getSizesImage(srcMatch[2]);
            if (imgSizes) {
                const {width, height} = imgSizes;
                if (!sizes.width || sizes.width === 'auto') {
                    sizes.width = width
                }   
                if (!sizes.height || sizes.height === 'auto') {
                    sizes.height = calculateHeight(width, height, sizes.width)
                }
                aspect_ratio = width/height;
            }
        } else if (sizes.width && sizes.height) {
            aspect_ratio = sizes.width / sizes.height
        }
        let attrWithSizes = attributes;
        if (attrWithSizes.endsWith('/')) attrWithSizes = attrWithSizes.slice(0, -1)
        let stylesImg = `style="${stylesMatch?.[2] ?? ''}`;
        if (isDOCX) { 
            if ((widthMatch && widthMatch[2] === "") || (heightMatch && heightMatch[2] === "")) {
                attrWithSizes = attrWithSizes?.replace(widthMatch[0], `width="${sizes.width}"`)?.replace(heightMatch[0], `height="${sizes.height}"`)
            } 
        } else {

            if (isPDF && sizes.width > MAX_WIDTH_PDF) {
                sizes.width = MAX_WIDTH_PDF
                sizes.height = sizes.width / aspect_ratio
                stylesImg += ` aspect-ratio: ${aspect_ratio};`
            }
            attrWithSizes = attributes?.replace(widthMatch[0], "")?.replace(heightMatch[0], "")
            stylesImg += ` width: ${sizes.width}px;`
        }
        
        stylesImg += ` max-width: 100%; height: auto;"`
        if (stylesMatch?.[0]) {   
            attrWithSizes = attrWithSizes.replace(stylesMatch[0], stylesImg)
        } else {
            attrWithSizes += ` ${stylesImg}`
        }
        
        replacedText = replacedText.replace(match, `<img ${attrWithSizes}>`)
    }
    replacedText =  replacedText?.replace(/\n/g, '')?.replace(/<p>\s*<\/p>/g, '');
    return replacedText;
};

const getSizesImage = async (url) => {
    const img = new Image();
    const imgLoadPromise = new Promise((resolve, reject) => {
        img.onload = function () { 
            resolve({width: this.width, height: this.height, aspetRatio: this.width / this.height})
        };
        img.onerror = function () { 
            reject(false)
        };
        img.src = url
    });
    const sizes = await imgLoadPromise
    return sizes
} 

const calculateHeight = (widthImg, heightImg, newWidth) => {
    let aspectRatio = widthImg / heightImg;
    return newWidth / aspectRatio;
}

export const parseData = (text) => {
    const isStringHTML = /<\/?[a-z][\s\S]*>/i.test(text)
    let content = text

    if (isStringHTML) {
        let element = document.createElement('div')
        element.innerHTML = text
        content = element?.textContent ?? element?.innerText
    }

    return content
}
