import { styled } from '@mui/system';
import { XButton } from '@ximdex/xui-react/material';
import { COLORS_MHE } from '../CONSTANTS';

export const XButtonNavy = styled(XButton)`
    ${(props) => !props.disabled && `
        background-color: ${COLORS_MHE.navy} !important;

        &:hover {
            background-color: ${COLORS_MHE.navy_500} !important;
            color: ${COLORS_MHE.red_academic} !important;
        }
    `}
`
