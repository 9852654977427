import React from 'react'

function PageNotFound() {
  return (
    <div>
      <img  
        src='/images/undraw_page_not_found.svg' 
        alt='page not found' 
        style={{ height: '55vh' }}
      />
      <h1>Page Not Found</h1>
    </div>
  )
}

export default PageNotFound