import React from 'react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
    const isAuth = sessionStorage.getItem('isAuth') && sessionStorage.getItem('isAuth') === 'true'

    if (!isAuth) {
      return <Navigate to='/' replace />
    }
  
    return children
}

export default ProtectedRoute