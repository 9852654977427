

//Old
/*Font.register({ family: 'Verdana', src: `${process.env.PUBLIC_URL}/fonts/Verdana.ttf`, fontStyle: 'normal', fontWeight: 'normal' });
    Font.register({ family: 'Verdana Bold', src: `${process.env.PUBLIC_URL}/fonts/VerdanaBold.ttf`, fontStyle: 'normal', fontWeight: 'bold' });
    Font.register({ family: 'Symbola', src: `${process.env.PUBLIC_URL}/fonts/Symbola.ttf` });
   // Font.register({ family: 'Verdana Bold', src: `/fonts/VerdanaBold.ttf`, fontStyle: 'normal', fontWeight: 'bold' });
    //Font.register({ family: 'Verdana', src: `https://fonts.cdnfonts.com/s/26084/VERDANAI.woff`, fontStyle: 'normal', fontWeight: 'normal' },{ family: 'Verdana Bold', src: `${process.env.PUBLIC_URL}/fonts/VerdanaBold.ttf`, fontStyle: 'normal', fontWeight: 'bold' })*/

import { Font } from "@react-pdf/renderer";

const FontRegister = [
    {
        family: "Verdana",
        src: `${process.env.PUBLIC_URL}/fonts/Verdana.ttf`,
        fontStyle: "normal",
        fontWeight: "normal",
    },
    {
        family: "Verdana Bold",
        src: `${process.env.PUBLIC_URL}/fonts/VerdanaBold.ttf`,
        fontStyle: "normal",
        fontWeight: "bold",
    },
    {
        family: "Verdana",
        src: `${process.env.PUBLIC_URL}/fonts/Verdanai.ttf`,
        fontStyle: "normal",
        fontWeight: 100,
    },
    { 
        family: "Symbola", 
        src: `${process.env.PUBLIC_URL}/fonts/Symbola.ttf` 
    },
    {
        family:"Calibri",
        fonts: [
            {
                src: process.env.PUBLIC_URL+"/fonts/Calibri100.ttf" ,
                fontWeight: 100,
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/CalibriItalic.ttf",
                fontStyle: "italic",
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/CalibriItalicBold.ttf",
                fontWeight: "bold",
                fontStyle: "italic",
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/CalibriBold.ttf",
                fontWeight: "bold",
            }
        ]
    },
    {
        family:"Liberation Sans",
        fonts: [
            {
                src: process.env.PUBLIC_URL+"/fonts/LiberationSans-Regular.ttf" ,
                fontStyle: "normal",
                fontWeight: 'normal',
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/LiberationSans-Italic.ttf",
                fontStyle: "italic",
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/LiberationSans-BoldItalic.ttf",
                fontWeight: "bold",
                fontStyle: "italic",
            },
            {
                src: process.env.PUBLIC_URL+"/fonts/LiberationSans-Bold.ttf",
                fontWeight: "bold",
            }
        ]
    },
];


Font.registerEmojiSource({
    format: 'png',
    url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

FontRegister.forEach((font) => {
    Font.register(font);
});

export default FontRegister;
