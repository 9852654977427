import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faDesktop, faLink, faPenToSquare, faClone } from '@fortawesome/free-solid-svg-icons';
import { XButton, XList } from '@ximdex/xui-react/material';
import {
    GeneratorLink,
    StyledDivList,
} from './StyledHome';
import { fetchExamGenerators, deleteGenerator } from '../../services/exam.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { useTranslation } from 'react-i18next';
import MessageToUser from '../../components/MessageToUser/MessageToUser';
import { LEGAL_NOTICES } from '../../CONSTANTS';
import { PageBox, PageContainer } from '../../styled-components/Container';
import { PageHeader, PageTitle } from '../../styled-components/Titles';
import { XButtonNavy } from '../../styled-components/Buttons';

const Home = () => {
    const [finalList, setFinalList] = useState([]);
    const [generators, setGenerators] = useState([]);
    const { showSpinner, hideSpinner } = useSpinner();
    const [messageToUser, setMessageToUser] = useState('')
    const [_t] = useTranslation("homePage")

    useEffect(() => {
        addControls();
    }, [generators]);

    useEffect(() => {
        sessionStorage.removeItem("currentLang")
        getGenerators()
    }, []);

    const getGenerators = () => {
        showSpinner()
        fetchExamGenerators().then(data => {
            const modifiedData = data.map(generator => {
                if (!generator.hash) throw new Error();
                
                return generator
            });
            setGenerators(modifiedData);
        }).catch(error => {
            console.error(error)
            alert(_t("messages.fail_fetch_generators"));
        }).finally(() => {
            hideSpinner();
        })
    }

    const confirmDeleteGenerator = (id, titleOfBook) => {
        if (window.confirm(_t("messages.confirm_delete_generator").replace("{title}", titleOfBook))) {
            deleteGenerator(id).then(res => {
                if (res.status === 200 || res.status === 202) {
                    getGenerators()
                    setMessageToUser(_t("messages.success_delete_generator"))
                } else (
                    setMessageToUser(_t("messages.fail_delete_generator"))
                )
            }).catch(err => {
                console.error(err) 
                setMessageToUser(_t("messages.fail_delete_generator"))
            })
        }
    }

    const copyGeneratorLinkToClipboard = (book) => {
        const generatorLink =  window.location.origin + '/generator/' + book.isbn + '_' + book.hash
        setMessageToUser(`${generatorLink} copied to clipboard.`)
        navigator.clipboard.writeText(generatorLink) 
    }

    const addControls = async () => {
        const listWithControls = generators.map(book => (
            {
                isbn: book.isbn,
                subject: book.title,
                controls:
                    <>
                        <GeneratorLink
                            to={`/generator/${book.isbn}_${book.hash}`}
                            state={{ book: book }}
                            target="_self" rel="noopener noreferrer"
                        >
                            <XButtonNavy>
                                <FontAwesomeIcon icon={faDesktop} size='1x' title={_t("tooltips.enter_generator")} />
                            </XButtonNavy>
                        </GeneratorLink>
                        <XButtonNavy onClick={() => copyGeneratorLinkToClipboard(book)}>
                            <FontAwesomeIcon icon={faLink} size='1x' title={_t("tooltips.copy_link")} />
                        </XButtonNavy>
                        <GeneratorLink
                            to={`/create-generator`}
                            state={{ 
                                book: {
                                    title: book?.title || '',
                                    subject: book?.subject || '',
                                    isbn: '',
                                    language_default: book?.language_default || 'ES',
                                    level: 1,
                                    url: 'https://www.ximdex.com/',
                                    demo_url: 'https://www.ximdex.com/',
                                    credits: book?.credits || '', 
                                    legal_notice: LEGAL_NOTICES[book?.language_default || 'ES'],
                                    version: 1,
                                    image: book?.image, 
                                }, 
                                clone: true 
                            }}
                        >
                            <XButtonNavy>
                                <FontAwesomeIcon icon={faClone} size='1x' title='Clone generator'/>
                            </XButtonNavy>
                        </GeneratorLink>
                        <GeneratorLink
                            to={`/edit-generator/${book.isbn}_${book.hash}`}
                            state={{ book: book, edit: true }}
                        >
                            <XButtonNavy>
                                <FontAwesomeIcon icon={faPenToSquare} size='1x' title={_t("tooltips.edit_generator")} />
                            </XButtonNavy>
                        </GeneratorLink>
                        <XButton onClick={() => confirmDeleteGenerator(book.id, book.title)}>
                            <FontAwesomeIcon icon={faTrashCan} size='1x' title={_t("tooltips.delete_generator")} />
                        </XButton>
                    </>
            }
        ));
        setFinalList(listWithControls);
    };

    return (
        <PageContainer>
            <PageBox>
                <PageHeader 
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <PageTitle>{_t("title")}</PageTitle>
                    <GeneratorLink
                        style={{
                            padding: '0 15px'
                        }}
                        to={'/create-generator'} 
                        state={{ edit: false }}
                    >
                        <XButton>
                            <FontAwesomeIcon icon={faPlusSquare} size='1x' style={{ marginRight: "16px", fontSize: '1.5rem' }} />
                            <span style={{}}>{_t("add_new_generator")}</span>
                        </XButton>
                    </GeneratorLink>
                </PageHeader>

                {finalList.length > 0 &&
                    <StyledDivList style={{overflowY: 'auto'}}>
                        <XList 
                            list={finalList} 
                            initRowsPerPage={25} 
                            rowsPerPageOptionsAvailable={[25, { label: _t("all"), value: -1 }]}
                            rowsPerPageLabel={_t("rows_per_page")}
                            displayedRowsLabels={{
                                of: _t("of")
                            }}
                        />
                    </StyledDivList>
                }

                {messageToUser &&
                    <MessageToUser 
                        messageToUser={messageToUser} 
                        setMessageToUser={setMessageToUser}
                    />
                }
            </PageBox>
        </PageContainer>
    )
}

export default Home;
