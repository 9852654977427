
import ReactDomServer from 'react-dom/server';
import styles from '../PdfExam/stylesPdfExam';
import { ACTIVITIES_OPEN_QUESTION } from '../../CONSTANTS';

const parse = require('html-react-parser');


const getHtmlTemplate = ({examData, showCorrectResults, htmls, options, target}) => {
    return ` 
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                /*
                    * {
                        font-family: 'Calibri';
                        font-size: 12px;
                    }
                */
                </style>
            </head>
            <body>
                    
                ${ReactDomServer.renderToString(
                <div style={{fontFamily: 'Calibri', fontSize: 12}} id="examDOC">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            marginTop: 20,
                            width: '100%',
                            maxHeight: '100%',
                            boxsizing: 'border-box'
                        }}
                    >
                        {examData?.activities?.map((activity, index) => {
                            return (
                                <div
                                    key={'examData_activity' + index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 5,
                                    }}
                                >
                                    {activity?.title && (
                                        <div custom-style="Activity Question" >
                                            <p style={styles.question}>{index + 1}) {parse(activity?.title)}</p>
                                        </div>
                                    )}
                                    {htmls[index] && (
                                        <div dangerouslySetInnerHTML={{ __html: htmls[index] }}></div>)
                                    }
                                    { true && (
                                        <div custom-style="Activity Answer" style={{maxWidth: 'calc(100% - 35px)', width: '100%', paddingLeft: 35 }}>
                                            {showCorrectResults && ACTIVITIES_OPEN_QUESTION.includes(activity.type) 
                                                ? (
                                                    <div key={'open_answer_' + activity.id} style={{ ...styles.answer, display: 'block' }}>
                                                        <span>✅</span>
                                                        <div style={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            display: 'initial'
                                                        }}>
                                                            <div 
                                                                dangerouslySetInnerHTML={{ 
                                                                    __html: target[index]
                                                                    // __html: decodeHTML(target, `<style>p{font-family: 'Calibri';font-size:12px;}</style>` + stylesBlock(true), true) 
                                                                }} 
                                                            ></div>
                                                        </div>
                                                    </div>
                                                ) : activity?.options?.map((option, indexOption) => {
                                                    return (    
                                                    <div
                                                        key={'options' + indexOption}
                                                        style={{
                                                            ...styles.options,
                                                            gap: 5,
                                                            alignItems: 'flex-start',
                                                            display: 'block'
                                                        }}
                                                    >

                                                        <p>

                                                        {option?.title && showCorrectResults && (
                                                            <span style={{ fontSize: 12 }}>
                                                                {option?.is_right?.toUpperCase() === 'TRUE' ? '✅ ' : '❌ '}
                                                            </span>
                                                        )}
                                                        <span style={{ fontSize: 12 }}>
                                                            {(indexOption + 10).toString(36) + ') '}
                                                        </span>

                                                        <span 
                                                            dangerouslySetInnerHTML={{
                                                                // __html: decodeHTML(option.title, stylesBlock(true), true) 
                                                                __html: options[index][indexOption] 
                                                            }}
                                                        ></span>
                                                        </p>
                                                    </div>
                                                )})
                                            }
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>

                </div>
            )}
            </body>
        </html>

`
}

export default getHtmlTemplate
