import styled from 'styled-components/macro'
import { XDropdown } from '@ximdex/xui-react/material'
import { COLORS_MHE } from '../CONSTANTS'

export const StyledXDropdown = styled(XDropdown)`
    // fix necessary for deployment 
    .css-17zbbii {
        margin: 16px 0px 8px;
    }

    div[role="button"] {
        // mhe red
        background-color: ${COLORS_MHE.red_academic_light};
        border: 1px solid rgb(229,27,35);   

        // mhe dark blue
        // background-color: rgba(10,34,61, 0.75);
        // border: 1px solid rgb(10,34,61);
    } 

    .MuiChip-deleteIcon {

        color: ${COLORS_MHE.red_academic} !important;
        opacity: 1 !important;
    }

    .css-wjsjww-MuiChip-label {
        color: ${COLORS_MHE.navy}!important;
    }
`
