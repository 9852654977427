import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import getConfigLanguages from './languages';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
i18next.init(getConfigLanguages())

const render = async () => {
    const Buffer = await import('buffer')
    global.Buffer = Buffer.Buffer

    ReactDOM.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

render();
