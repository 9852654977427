import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./stylesPdfExam";

const HeaderPdfExam = (props) => {
    const examData = props.examData;
    const logo     = props.logo;
    return (
        <>
            <Image alt="logo-mhe" src={logo} style={styles.logoMhe} fixed />
            {examData?.title && (
                <Text style={styles.header} fixed>
                    {examData?.title}
                </Text>
            )}
            <View style={styles.table_header}>
                <View style={[styles.row_header]}>
                    <Text style={[styles.cell_header]}>{examData.name}</Text>
                    <Text style={[styles.cell_header]}>{examData.surname}</Text>
                </View>
                <View style={[styles.row_header]}>
                    <Text style={[styles.cell_header]}>{examData.date}</Text>
                    <Text style={[styles.cell_header]}>{examData.course}</Text>
                </View>
            </View>
        </>
    );
};
export default HeaderPdfExam;
