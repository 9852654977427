import { styled } from '@mui/system';
import { XBox } from '@ximdex/xui-react/material';
import { PageContent } from '../../styled-components';

export const GeneratorContainer = styled('div')`
    width: 80%;
    margin: 0 auto;
`;

export const StyledXBox = styled(PageContent)`
    position: relative;
    border: none;
    padding: 24px;
    width: unset !important;

    ${props => props.backgroundImage &&
        `::before {    
            content: "";
            background-image: url(${props.backgroundImage});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.05;
      }`
    } 
`;

export const StyledHelperText = styled('p')`
    text-align: left;
    font-size: 0.8rem;
    margin: 0 0 0 12px;
    color: ${props=> props.type === 'info' ? '#5c9ad0' : props.type === 'error' ? '#e13144' : '#000'}
`
