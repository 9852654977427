import { styled } from '@mui/system';
import { COLORS_MHE } from '../CONSTANTS';

export const PageHeader = styled('div')`
    width: 100%;
    display: flex;
    border-bottom: 1px solid ${COLORS_MHE.navy_100};
    background-color: ${COLORS_MHE.navy_50};
`;

export const PageTitle = styled('h1')`
    font-size: 2.5rem;
    margin: 15px;
    padding: 0 15px;
    font-weight: 600;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: ${COLORS_MHE.navy};
`;
