import { styled } from '@mui/system'
import { XButton } from '@ximdex/xui-react/material'

export const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: #fff;
    /* box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
    width: 500px;
    margin: 0 auto;
    padding: 0 2em 0 2em;
`

export const StyledSubmitButton = styled(XButton)`
    margin: 1em 0 8px 0;
`
