
export const stylesheetPDF = {
    p: {
        fontFamily: 'Liberation Sans',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    
    li: {
        maxWidth: '100%',
        fontFamily: 'Liberation Sans',
        fontSize: 12
    },
    ul: {
        fontFamily: 'Liberation Sans',
        maxWidth: '100%',
        paddingRight: 30,
    },
    ol: {
        fontFamily: 'Liberation Sans',
        maxWidth: '100%',
        paddingRight: 30,
    },
    '*': {
        fontFamily: 'Liberation Sans',
        fontSize: 12

    },
    tr: {
        border: '1px solid black',
    },
    table: {
        border: '1px solid black',
        collapse: 'collapse',
        fontFamily: 'Liberation Sans',
        fontSize: 12
    },
    td: {
        border: '1px solid black',
        height: 'auto'
    },
    th: {
        border: '1px solid black',
    },
    body: {
        fontFamily: 'Liberation Sans',
        fontSize: 12
    },
    strong: {
        fontFamily: 'Liberation Sans',
        fontSize: 12
    },
    span: {
        fontFamily: 'Liberation Sans',
        fontSize: 12
    }
}

export default stylesheetPDF
