import { StyleSheet } from "@react-pdf/renderer";
import { MAX_WIDTH_PDF } from "../../helpers/functionsExam";
const styles = StyleSheet.create({
    body: {
        paddingTop: 46,
        paddingBottom: 65,
        paddingHorizontal: 35   
    },
    title: {
        fontSize: 14,
        textAlign: "center",
        fontWeight: "bold",
    },
    question: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "Liberation Sans",
        width: '100%',
        margin: 0,
        padding: 0,
    },
    options: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        maxWidth:"100%",
        width: '100%',
    },
    option: {
    },
    answer: {
        fontSize: 12,
        display: "flex",
        flexDirection: "row",
        gap: 15,
        width: '100%',
        maxWidth: '100%'
    },
    symbol: {
        fontFamily: "Symbola",
    },
    correct: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#95cda9",
    },
    text: {
        marginTop: 3,
        fontSize: 12,
        textAlign: "justify",
        maxWidth: MAX_WIDTH_PDF
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
        width: 100,
        height: 100,
    },
    formula: {
        maxHeight: "200px",
        margin: "0 auto 12px auto",
    },
    optionImage: {
        maxHeight: "200px",
        margin: "0 auto 0 0",
    },
    header: {
        fontSize: 16,
        marginBottom: 20,
        marginLeft: 55,
        paddingBottom: 4,
        textAlign: "right",
        color: "grey",
        borderBottom: "1px solid black",
    },
    logoMhe: {
        position: "absolute",
        top: 20,
        left: 35,
        width: 50,
        height: 50,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 11,
        bottom: 30,
        left: 0,
        right: 35,
        textAlign: "right",
        color: "grey",
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
    },
    table_header: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Verdana",
        fontSize: 12,
    },
    row_header: {
        display: "flex",
        flexDirection: "row",
        flexBasis: 22,
    },
    cell_header: {
        flexGrow: 1,
        flexBasis: "auto",
    }
});
export const stylesBlock = (isPDF) =>  `
    <style>
        ${ isPDF ? ` 
                p {
                    margin-bottom: 12px !important;
                    margin-top: 0px !important;
                }
            ` : ''
        }
       
        #examDOC > * {
            font-family: 'Liberation Sans' !important;
            font-size: 12px !important;
        }
       
        td {
            border: 1px solid black; 
            ${!isPDF ? `
            padding: 8px;
            width: 50px;
            ` : ''}
        }
    </style>
`

export default styles;
