import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import {
    ExamTitle,
    ActivityTitle,
    ActivityList,
    ActivityContent,
    ExamContainer,
    ActivityContainer,
    ActivityListElement,
    ExamHeader,
    ExamContent,
    ButtonsContainer,
    SwitchContainer,
    StyledXLabel,
    ActivityActionsButtons,
    XboxExman,
    ButtonMove,
    ContainerButtonsMove,
    LoadContainer,
    CorrectionSymbol,
    ContaninerButtonActions,
} from './StyledExam';
import DOMPurify from 'isomorphic-dompurify';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PdfExam from '../PdfExam/PdfExam';
import { XButton, XSwitch, XSpinner } from '@ximdex/xui-react/material';
import { ACTIVITIES_OPEN_QUESTION, ACTIVITIES_CLOSED_QUESTION } from './../../CONSTANTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faShuffle, faArrowCircleUp, faArrowCircleDown, faSpinner, faCircleExclamation, faDownload } from '@fortawesome/free-solid-svg-icons'
import { getRandonActivity } from '../../services/exam.service'
import { decodeHTML, wrapImagesWithDiv } from '../../helpers/functionsExam';
import { stylesBlock } from '../PdfExam/stylesPdfExam';
import config from '../../config';
import useHtml2Docx from '../DocExam/useHtml2Docx';
import { PageBox, PageContainer, PageContent, PageFooter } from '../../styled-components/Container';
import { PageHeader, PageTitle } from '../../styled-components/Titles';
import { XButtonNavy } from '../../styled-components/Buttons';

const Exam = ({ isbn, units, language_default, oc_type, data, setActivities }) => {
    const [_t] = useTranslation("examView")

    const [tables, setTables] = useState([]);
    const [showCorrectResults, setShowCorrectResults] = useState(false);
    const [dataFormated, setDataFormated] = useState(null)
    const [activitiesToReload, setActivitiesToReload] = useState([])
    // const [isLoadingDOC, setIsLoadingDOC] = useState(false)
    const [isLoadingPDF, setIsLoadingPDF] = useState(false)
    const [error, setError] = useState({
        doc: false, 
        pdf: false
    })
    const [htmls, setHtmls] = useState({})
    const [debug, setDebug] = useState(window.ximdex_debug ?? {pdf: false, doc: false})
    const {loadingDOC: isLoadingDOC, handleDownload: handleDownloadDocx, errorDoc,  handleDOC , htmlDoc} = useHtml2Docx(data?.title ?? 'exam', language_default, oc_type, data?.activities ?? [])

    window.onDebug = ({pdf = false, doc = false}) => {
        if (typeof pdf !== 'boolean') pdf = false;
        if (typeof doc !== 'boolean') doc = false;
        if (pdf && doc) doc = false;
        window.ximdex_debug = {pdf, doc}
        setDebug({pdf, doc})
    }

    useEffect(() => {
        formatActivities();
    }, [data]);

    useEffect(() => {
        if (errorDoc) setError({...error, doc: errorDoc})
    }, [errorDoc])

    useEffect(() => {
        setIsLoadingPDF(true)
    }, [htmls, showCorrectResults])


    useEffect(() => {
        let idtimeout =setTimeout(() => {
            setIsLoadingPDF(false)
        }, 2000)

        return () => clearTimeout(idtimeout)
    },[isLoadingPDF])

    const handleDownload = ({ blob, url, loading, error }) => {
        return (loading ? 'Cargando el documento...' : 'Descargar')
    }

    const handle_download_doc = (examData, htmls) => {
        handleDOC(examData, htmls)
    }

    const  handleDownloadDOC = (dataFormated, tables, showCorrectResults) => {
        if (isLoadingDOC || error.doc) return;
        return handleDownloadDocx();

        // setIsLoadingDOC(true)
       async function fetchData() {
        try {
          const response = await fetch(config.XEVAL_URI,{
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({html:dataFormated.activities[0].text})
          });
        } catch (error) {
          console.error('Error:', error);
        }
      }
      
      fetchData();
    }

    const parseText = (activity) => {
        activity.text_preview = activity.text.replace(/<[^>]+>/g, ' ').trim();
        activity.text = activity.text.replace(/font-family:\s*'[^']+'/g, "font-family: 'Calibri'");

        if (activity?.options?.length > 0) {
            activity.options.forEach((option, index) => {
                activity.options[index].title_preview = option?.title?.replace(/<[^>]+>/g, ' ').trim()
                activity.options[index].title = option?.title?.replace(/font-family:\s*'[^']+'/g, 'Calibri;')
            })
        };

        return activity;
    }    
    
    const getNewSrcWithDetails = (sources) => {
        let newFormulas = [];
        let newFormulasWithDetails = [];

        const newSrc = sources.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
        let width = sources.map(x => x.replace(/.*width="([^"]*)".*/, '$1'));
        let height = sources.map(x => x.replace(/.*height="([^"]*)".*/, '$1'));    

        newSrc?.forEach((source, index) => {
            if ((width[index] === 'auto' && height[index] === 'auto') || parseInt(height[index], 10) > 200) {
                height[index] = [ 200 ]
            };

            newFormulas.push(source);
            newFormulasWithDetails.push({
                src: source, 
                width: parseInt(width[index], 10) || 200, 
                height: parseInt(height[index], 10) || 200
            }); 
        });


        return {formulas: [...newFormulas], formulasWithDetails: [...newFormulasWithDetails]};
    }
    
    const searchFormula = (activity) => {
        const sources = activity.text.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
        if (sources?.length > 0) {
            const newSrcWithDetails = getNewSrcWithDetails(sources)

            activity.formulas = newSrcWithDetails.formulas
            activity.formulasWithDetails = newSrcWithDetails.formulasWithDetails

            activity.warning = _t("messages.alert_activity_images")
            console.warn(_t("messages.alert_activity_images"), activity.text)
        };

        return activity;
    }

    const searchOptionsFormula = (activity) => {
        activity?.options?.forEach((option, optionIndex) => {
            const sources = option?.title?.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
            if (sources?.length > 0) {
                const newSrcWithDetails = getNewSrcWithDetails(sources)

                activity.options[optionIndex].formulas = newSrcWithDetails.formulas
                activity.options[optionIndex].formulasWithDetails = newSrcWithDetails.formulasWithDetails

                activity.warning = _t("messages.alert_activity_images")
                console.warn(_t("messages.alert_activity_images"), activity.options[optionIndex].title)
            }
        });

        return activity;
    }

    const getTables = (activity, index) => {
        activity.text = activity.text.replace('<table', `<table data-activity=${index}`);
        const tables = document.getElementsByTagName('table');
        setTables(tables);
    }

    const formatActivities = () => {
        const examDataCopy = { ...data };
        examDataCopy.activities.map((activity, index) => {
            let activityHtml = decodeHTML(activity.text,stylesBlock(false));
            let _html = activityHtml
            wrapImagesWithDiv(activityHtml)
                .then(html => _html = html)
                .catch(e => console.error(e))
                .finally(() => {
                    setHtmls(prevState => ({...prevState, [index]: _html}))
                })
            parseText(activity);
            searchFormula(activity);
            searchOptionsFormula(activity);
            getTables(activity, index);
            return activity;
        });
        examDataCopy.name = _t("header.name");
        examDataCopy.surname = _t("header.surname");
        examDataCopy.date = _t("header.date");
        examDataCopy.course = _t("header.course");

        setDataFormated(examDataCopy);
    }

    const deleteActivity = async (index) => {
        if (window.confirm(_t("messages.confirm_remove_activity"))) {
            const activities = dataFormated.activities.filter((activity, i) => i !== index)
            setActivities(activities)
        }
    }

    const moveActivity = (type, index) => {
        switch (type) {
            case 'up':
                if (index > 0) {
                    const activities = [...dataFormated.activities]
                    const activity = activities[index]
                    activities[index] = activities[index - 1]
                    activities[index - 1] = activity
                    setActivities(activities)
                }
                break;
            case 'down':
                if (index < dataFormated.activities.length - 1) {
                    const activities = [...dataFormated.activities]
                    const activity = activities[index]
                    activities[index] = activities[index + 1]
                    activities[index + 1] = activity
                    setActivities(activities)
                }
                break;
            default:
                break;
        }
    }

    const changeActivity = async (id, index) => {
        let activitiesReload = [...activitiesToReload]
        const activities = [...dataFormated.activities]
        activitiesReload.push(id);
        setActivitiesToReload(activitiesReload)

        const newActivity = await getRandonActivity(isbn, units, language_default, oc_type, activities.map(e => e.id))
        if (newActivity !== null) {
            activities[index] = newActivity
        } else {
            alert(_t("messages.fail_no_activities"))
        }

        setActivities(activities)
        setActivitiesToReload(activitiesReload.filter(e => e !== id))
    }

    const handleLoadPDF = (value) => setIsLoadingPDF(value)

    if (dataFormated === null) return null;
    
    return (
        <PageContainer>
            <PageBox style={(debug.pdf || debug.doc) ? {maxWidth: '50%'} : {}}>  
                <PageHeader>
                    <PageTitle>{dataFormated?.title}</PageTitle>
                </PageHeader>

                <PageContent>
                    {dataFormated.activities.map((activity, activityIndex) => {
                        if (activitiesToReload.includes(activity.id)) {
                            return <LoadContainer key={'activitiesToReload_' + activity.id}>
                                <XSpinner
                                    width="50px"
                                    border="10px"
                                />
                            </LoadContainer>
                        }

                        return <ActivityContainer key={'examPDF_' + activity.id} id={"activity_" + activity.id}>
                            <ActivityActionsButtons>
                                {dataFormated.activities.length > 1 &&
                                    <ContainerButtonsMove>
                                        <ButtonMove
                                            onClick={() => moveActivity("up", activityIndex)}
                                            style={{ marginTop: "0.5rem", }}
                                            disabled={activityIndex === 0}
                                        >
                                            <FontAwesomeIcon icon={faArrowCircleUp} size='1x' title={_t("tooltips.move_up")} />
                                        </ButtonMove>
                                        <ButtonMove
                                            onClick={() => moveActivity("down", activityIndex)}
                                            style={{ marginTop: "0.5rem", }}
                                            disabled={activityIndex === dataFormated.activities.length - 1}
                                        >
                                            <FontAwesomeIcon icon={faArrowCircleDown} size='1x' title={_t("tooltips.move_down")} />
                                        </ButtonMove>
                                    </ContainerButtonsMove>
                                }
                                <XButtonNavy
                                    onClick={() => changeActivity(activity.id, activityIndex)}
                                >
                                    <FontAwesomeIcon icon={faShuffle} size='1x' title={_t("tooltips.change_question")} />
                                </XButtonNavy>
                                <XButton
                                    onClick={() => deleteActivity(activityIndex)}
                                    color="danger"
                                    style={{ marginTop: "0.5rem", }}
                                >
                                    <FontAwesomeIcon icon={faTrashCan} size='1x' title={_t("tooltips.delete_question")} />
                                </XButton>

                            </ActivityActionsButtons>

                            <ActivityList key={'activity_' + activityIndex}>
                                <ActivityTitle>
                                    {activity?.warning && <FontAwesomeIcon icon={faCircleExclamation} size='1x' style={{ placeSelf: 'center', color: '#e51b23', paddingRight: '6px' }} title={activity.warning} />}
                                    {activityIndex + 1}) {activity?.title ? activity.title : `Activity #{${activity?.id}}`}
                                </ActivityTitle> 
                                {htmls[activityIndex] && // TODO function check if activity.text is HTML
                                    <ActivityContent dangerouslySetInnerHTML={{ __html: htmls[activityIndex]  }} />
                                }
                                {!activity?.text_preview && !activity?.text.startsWith('<') &&
                                    <ActivityContent>{activity.text}</ActivityContent>
                                }
                                
                                {ACTIVITIES_CLOSED_QUESTION.includes(activity.type) && activity?.options.map((option, optionIndex) => (
                                    <div key={`option_${activity.id}_${optionIndex}`} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: option?.title_preview ? "column" : "row" }}>
                                        <div style={{ display: 'flex' }}>
                                            {showCorrectResults && 
                                                <CorrectionSymbol isRight={option.is_right}>
                                                    {option.is_right === 'TRUE' ? '✔ ' : '✘ '}
                                                </CorrectionSymbol> 
                                            }
                                            <ActivityListElement 
                                                key={'examPDF_option_' + activity.id + '_' + optionIndex} 
                                                marginLeft={showCorrectResults ? '1.5rem' : '3rem' }
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(decodeHTML(option?.title,stylesBlock(false))) }} 
                                            />   
                                        </div>
                                    </div>
                                ))}
                                {ACTIVITIES_OPEN_QUESTION.includes(activity.type) && !showCorrectResults && (
                                    <div style={{ height: '4em' }}></div>
                                )}
                                {ACTIVITIES_OPEN_QUESTION.includes(activity.type) && showCorrectResults && (
                                    <div style={{ display: 'inline-flex', margin: '0.5rem 0 0 0', minHeight: 'calc(2em - 0.5rem)', alignItems:'baseline',width:"100%"}}>
                                        <CorrectionSymbol isRight={"TRUE"}>
                                            {'✔ '}
                                        </CorrectionSymbol> 
                                        {activity.type === 'input-long'  // TODO function check if activity.text is HTML
                                            ? <ActivityContent style={{ marginLeft: '0.5rem',width:"100%",fontFamily: 'Calibri'}} dangerouslySetInnerHTML={{__html: activity.targets[0].accepted_values.join(', ') }} />
                                            : <ActivityContent style={{ marginLeft: '0.5rem',width:"100%",fontFamily: 'Calibri'}} dangerouslySetInnerHTML={{__html: activity.targets[0].accepted_values.join(', ')}} />
                                        }
                                    </div>  
                                )}
                            </ActivityList>
                        </ActivityContainer>
                        
                    })}
                </PageContent>

                <PageFooter>
                    <SwitchContainer >
                        <StyledXLabel
                            style={{alignItems: 'center'}}
                            label={_t("active_download_solutions").toUpperCase()}
                            title={ _t("active_download_solutions").toUpperCase()+'?'}
                            labelLocation="left"
                            component={
                                <XSwitch 
                                    id="correct-results" 
                                    color="secondary"
                                    onChange={() => setShowCorrectResults(!showCorrectResults)} 
                                />
                            }
                            htmlFor="correct-results" 
                        />
                    </SwitchContainer>
                    <ContaninerButtonActions>
                        <PDFDownloadLink
                            style={{ textDecoration: 'none', width: '-webkit-fill-available'}}
                            document={
                                <PdfExam
                                    handleLoadPDF={handleLoadPDF}
                                    content={dataFormated}
                                    showCorrectResults={showCorrectResults}
                                    handleDOC={handleDOC}
                                />
                            }
                            fileName={dataFormated.title}
                        >
                            <XButton
                                disabled={isLoadingPDF || !!error?.pdf}
                                title={isLoadingPDF
                                    ? _t("buttons.loading")
                                    : error?.doc 
                                        ? _t("buttons.error") 
                                        : _t("buttons.donwload_pdf")
                                } 
                                onClick={handleDownload}
                                style={{ 
                                    width: '-webkit-fill-available', 
                                    height: '-webkit-fill-available',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                < FontAwesomeIcon 
                                    icon={isLoadingPDF
                                            ? faSpinner
                                            : error?.doc
                                                ? faCircleExclamation
                                                : faDownload
                                    } 
                                    size='1x' 
                                    style={{ placeSelf: 'center'}}
                                />
                                PDF       
                            </XButton>
                        </PDFDownloadLink>
                        <XButton 
                            onClick={() => handleDownloadDOC(dataFormated, tables, showCorrectResults)}
                            style={{ 
                                width: '-webkit-fill-available',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 15,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            disabled={isLoadingDOC  || !!error?.doc}
                            title={isLoadingDOC 
                                    ? _t("buttons.loading")
                                    : error?.doc 
                                        ? _t("buttons.error") 
                                        : _t("buttons.donwload_doc")

                                }
                        >
                            < FontAwesomeIcon 
                                icon={isLoadingDOC
                                        ? faSpinner
                                        : error?.doc
                                            ? faCircleExclamation
                                            : faDownload
                                } 
                                size='1x' 
                                style={{ placeSelf: 'center'}}
                            />
                            DOC
                        </XButton>
                    </ContaninerButtonActions>
                </PageFooter>
            </PageBox>
            {debug.pdf && (
                <PDFViewer style={{flexGrow: 1}}>
                    <PdfExam
                        handleLoadPDF={handleLoadPDF}
                        content={dataFormated}
                        showCorrectResults={showCorrectResults}
                        // handleDOC={handleDOC}

                    />
                </PDFViewer>
            )}
           { debug.doc && (
                <div 
                    style={{
                        backgroundColor: 'white',
                        padding: '1em',
                        width: '50%',
                        overflowY: 'auto',

                    }}
                    dangerouslySetInnerHTML={{__html: htmlDoc}}
                ></div>
           )} 
        </PageContainer>  
    )
}

export default Exam;
