import { View, Text } from "@react-pdf/renderer";
import styles, { stylesBlock } from "./stylesPdfExam";
import { decodeHTML, wrapImagesWithDiv } from "../../helpers/functionsExam";
import { Html } from 'react-pdf-html';
import { useEffect, useState } from "react";
import { stylesheetPDF } from "./StylesheetsPDF";

const ShowActivity = ({ indexOption, option, isPDF, handleLoadPDF, showCorrectResults }) => {
    const [html, setHtml] = useState(false)

    useEffect(() => {
        if (isPDF) handleLoadPDF(true)
        let htmlOption = decodeHTML(option.title, stylesBlock(isPDF), isPDF)

        wrapImagesWithDiv(htmlOption, "", isPDF)
            .then(_html => htmlOption = _html)
            .catch(console.error)
            .finally(() => {
                setHtml(htmlOption)
            })

    }, [option.title])

    useEffect(() => {
        if (html && isPDF) handleLoadPDF(false)
    }, [html])

    return (
        <View
            key={'options' + indexOption}
            style={{
                ...styles.options,
                gap: 5,
                alignItems: 'flex-start',
            }}
        >

            {option?.title && showCorrectResults && (
                <Text style={{fontSize: 12}}>
                    {option?.is_right?.toUpperCase() === 'TRUE' ? '✅' : '❌'}
                </Text>
            )}
             <Text style={{fontSize: 12}}>
                    {(indexOption + 10).toString(36) + ') '}
                </Text>
            {html && (
                <Html 
                    stylesheet={{...stylesheetPDF, p: {...stylesheetPDF.p, margin: 0, marginBottom: 5}}}
                >{html}</Html>
            )}
        </View>
    )
}

export default ShowActivity;
