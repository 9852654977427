import { styled } from '@mui/system';
import { XBox, XButton, XLabel } from '@ximdex/xui-react/material';
import { COLORS_MHE } from '../../CONSTANTS';
import { XButtonNavy } from '../../styled-components/Buttons';

export const MainTitle = styled('h3')`
    font-size: 2rem;
    font-weight: 200;
    margin: 1rem 0;
`;

export const XboxExman = styled(XBox)`
    margin: 0;
    padding: 0;
    max-width: 1200px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
    border: unset; 
    border-radius: 0;
    background-color: ${COLORS_MHE.white};

    @media only screen and (max-width: 900px ){
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

export const ExamHeader = styled('div')`
    width: 100%;
    display: flex;
    border-bottom: 1px solid ${COLORS_MHE.navy_100};
    background-color: ${COLORS_MHE.navy_50};
`;

export const ExamTitle = styled('h1')`
    font-size: 2.5rem;
    margin: 15px;
    padding: 0 15px;
    font-weight: 600;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: ${COLORS_MHE.navy};
`;

export const ExamContent = styled('div')`
    overflow-y: auto;
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
`

export const ExamContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 15px;
    background-color: ${COLORS_MHE.light_gray};
`;

export const ActivityActionsButtons = styled(({ className, ...props }) => <div className={'action-buttons ' + className}{...props}></div>)`
    display: none;
    flex-direction: column;
    padding: 15px;
`

export const ActivityContainer = styled('div')`
    display: flex;
    flex-direction: row-reverse;
    margin: 1rem 2rem;
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    

    &:hover {
        border: 1px solid #d0d0d0;
        border-radius: 10px;

        & .action-buttons{
            display: flex;
        }
    }
`;

export const LoadContainer = styled(ActivityContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActivityTitle = styled('span')`
    font-weight: 500;
`;

export const ActivityContent = styled('div')`
   
`;

export const ActivityList = styled('ol')`
    list-style-type: lower-alpha;
    flex-grow: 1;
`;

export const ActivityListElement = styled('li')`
   margin-left: ${props => props.marginLeft ?? '3rem'};
   
   p {
       margin: 0;
   }
`;

export const CorrectionSymbol = styled('p')`
    margin: 0 0 0 1.5rem; 
    color: ${props => props.isRight === 'TRUE' ? 'green' : 'red'};
`

export const ButtonsContainer = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    padding: 15px;
    gap: 16px;
    border-top: 1px solid ${COLORS_MHE.navy_100};
    background-color: ${COLORS_MHE.navy_50};
`;

export const ActivityImage = styled('img')`
   max-height: 200px;
   margin: 0.5em auto;
`;

export const OptionImage = styled(ActivityImage)`
   max-height: 200px;
   margin: ${props => props.hasTitle ? '0 auto 0 3em' : '0 auto 0 0'};
`

export const SwitchContainer = styled('div')`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    
    /* gap: 5px; */
    /* padding: 12px 16px; */
    /* margin-right: 4em; */
    /* background-color: #fff; */
    /* box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
    /* border-radius: 8px */
    width: 60em;
`

export const CorrectAnswer = styled('p')`
    color: #95cda9;
    font-weight: bold;
    font-size: 1.25rem;
`

export const ContainerButtonsMove = styled('div')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-bottom: 8px;
`
export const ButtonMove = styled(XButtonNavy)`
    padding: 5px !important;
    margin: 0 !important;
    min-width: min-content;
`

export const StyledXLabel = styled(XLabel)`
    width: -webkit-fill-available;
    gap: 15px;
    font-weight: bold;

    & label {
        margin: 0 !important;
        padding: 0 !important;
    }
`

export const ContaninerButtonActions = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
`
