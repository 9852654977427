import styled from "styled-components/macro"
import { XNav } from '@ximdex/xui-react/material'
import { COLORS_MHE } from "../CONSTANTS";

export const StyledXNav = styled(XNav)` 
    position: relative !important;
    z-index: 9 !important;

    margin-bottom: 0;
    /* box-shadow: none !important; */
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    background-color: ${COLORS_MHE.navy} !important;
    padding-top: 5px;
    
    background-color: white;
    & div {
        color: ${COLORS_MHE.white};
        font-weight: bold;

        & a {
            font-size: 24px
        }

        & :hover {
            color: ${COLORS_MHE.red_secondary};

            & a:hover {
                border-bottom: none;
            }
        }
    };
`;
