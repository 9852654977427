import { Text, View } from "@react-pdf/renderer";
import { decodeHTML, wrapImagesWithDiv } from "../../helpers/functionsExam";
import { Html } from 'react-pdf-html';
import styles, { stylesBlock } from "./stylesPdfExam";
import { useEffect, useState } from "react";

const ShowCorrectResultsOpenQuestion = ({activity, isPDF, handleLoadPDF, showCorrectResults}) => {

    const [html, setHtml] = useState(false)
    useEffect(() => {
        if (isPDF) handleLoadPDF(true)
        let _html = decodeHTML(activity.targets[0].accepted_values.join(' '), `<style>p{font-family: 'Calibri';font-size:12px;}</style>` + stylesBlock(isPDF), isPDF)
        wrapImagesWithDiv(_html, "", isPDF)
            .then(newHtml => setHtml(newHtml))
            .catch(e => {
                console.error(e)
                setHtml(_html)
            })
    }, [activity.targets])
    
    useEffect(() => {
        if (html && isPDF) handleLoadPDF(false)
    }, [html])

    if (!showCorrectResults) return null;
    
    return (
        <View key={'open_answer_' + activity.id} style={{...styles.answer}}>
            <Text>✅</Text>
            {html && (
                <View style={{
                    width: '100%',
                    maxWidth: '100%',
                    display: 'initial'
                }}>
                    <Html >{html}</Html>
                </View>
            )}
        </View>
    )
}
    
export default ShowCorrectResultsOpenQuestion;
