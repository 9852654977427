import { styled } from '@mui/system'
import { COLORS_MHE } from '../../CONSTANTS'

export const StyledDiv = styled('div')`
    box-shadow: 0px 1px 2px -1px ${COLORS_MHE.gray} , 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px ${COLORS_MHE.gray} !important;
    padding: 4px 16px;
    position: absolute;
    top: 95px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    background: ${COLORS_MHE.red_academic_light  };
    width: 75vw;
`
