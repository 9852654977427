import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useSpinner } from '@ximdex/xui-react/hooks';
import { XButton } from '@ximdex/xui-react/material';
import { CoverContainer } from './StyledGeneratorView';
import ActivitiesList from '../../components/ActivitiesList/ActivitiesList';
import ExamForm from '../../components/ExamForm/ExamForm';
import { fetchExamGeneratorByIsbn, getUnitsByIsbn, getActivitiesByIsbnAndUnits } from '../../services/exam.service';
import { ExamHeader, ExamTitle } from '../../components/Exam/StyledExam';
import { COLORS_MHE } from '../../CONSTANTS';
import { PageContainer, PageContent, PageFooter } from '../../styled-components';
import { PageHeader, PageTitle } from '../../styled-components/Titles';

function useBook() {
    const { isbnUnderscoreHash } = useParams();
    const { showSpinner, hideSpinner } = useSpinner();
    const [booksState, setBook] = useState(null);
    const [_t] = useTranslation("generatorView");

    useEffect(() => {
        showSpinner();

        fetchExamGeneratorByIsbn(isbnUnderscoreHash)
            .then(generator => ({
                ...generator,
                isbn: generator.isbn,
                hash: generator.hash,
            }))
            .then(setBook)
            .catch(() => {
                alert(_t("messages.fail_fetch_generator"))
                setBook(undefined)
            })
            .finally(() => hideSpinner())
    }, []);

    return { book: booksState };
}

const GeneratorView = () => {
    const { book } = useBook();
    const initActivitiesState = { 
        isLoading: false, 
        total: 0 
    };
    const [activities, setActivities] = useState(initActivitiesState); 
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedActivityTypes, setSelectedActivityTypes] = useState('all');
    const [generatedExam, setGeneratedExam] = useState({});
    const [showGeneratorFrontPage, setShowGeneratorFrontPage] = useState(true);
    const [_t] = useTranslation("generatorView");
    const { showSpinner, hideSpinner } = useSpinner();

    const [units, setUnits] = useState(undefined);
    const [loadActivities, setLoadActivities] = useState(true);
    const [unitsStorage, setUnitsStorage] = useState({})


    let isbn = book?.isbn, 
        language_default = book?.language_default;
    
    useEffect(() => {
        const init = async () => {
            const bookLang = book?.language_default?.slice(0, 2)?.toLowerCase()
            if (bookLang && bookLang !== i18next.language) {
                i18next.changeLanguage(bookLang)
                sessionStorage.setItem("currentLang", bookLang)
            }

            if (book?.isbn) {
                if (units) setUnits(undefined)
                const newUnits = await getUnitsByIsbn(book, selectedActivityTypes)
                setUnits(newUnits)
            }

            setActivities(initActivitiesState)
        }
        if (book) init()
    }, [book, selectedActivityTypes]);

    useEffect(() => {
        if (selectedUnits.length > 0) {
            getAllActivities()
        } else {
            setActivities(initActivitiesState)
        }
    }, [activities?.current_page, loadActivities]);

    const getAllActivities = async () => {
        showSpinner();
        
        try {
            let units = {};
            const unitsToGet = selectedUnits.filter(e => !unitsStorage.hasOwnProperty(e.id)).map(e => e.id)

            const newActivitiesToSave = unitsToGet.length > 0
                ? await getActivitiesByIsbnAndUnits(isbn, unitsToGet, selectedActivityTypes, language_default, activities?.current_page)
                : {data: []}
            let activitiesMap = new Map()
            newActivitiesToSave.data.forEach(activity => {
                const modifiedActivity = {
                    id: activity.id,
                    title: activity.title,
                    text: activity.text,
                    type: activity.type,
                    subtype: selectedActivityTypes, 
                    units: activity.units
                };

                activity.units.forEach(unit => {
                    if (!units[unit]) units[unit] = {}
                    if (!units[unit][activity.id]) units[unit][activity.id] = modifiedActivity
                })
                
                activitiesMap.set(activity.id, modifiedActivity);
            })
            let activitiesList = Array.from(activitiesMap.values())
            const newUnitsStorage = {...unitsStorage, ...units}

            selectedUnits.forEach(({id})=> {
                Object.keys(newUnitsStorage[id]).forEach(activityID => {
                    if (!activitiesList.some(act => act.id === activityID)) {
                        activitiesList.push(newUnitsStorage[id][activityID])
                    }
                })
            })
            activitiesList = activitiesList
                .filter(act => {
                    if (selectedActivityTypes.toUpperCase() === 'ALL' || act.subtype.toUpperCase() === selectedActivityTypes.toUpperCase()) {
                        return true
                    }
                    return false
                })
            let newActivitiesMap = new Map()
            selectedUnits.forEach(({id}) => {
                Object.keys(newUnitsStorage?.[id] ?? []).forEach(actID => {
                    newActivitiesMap.set(actID, newUnitsStorage[id][actID])
                })
            })
            activitiesList = Array.from(newActivitiesMap.values())
            setUnitsStorage(newUnitsStorage)
            setActivities({isLoading: false, total: activitiesList.length, data: activitiesList});
            // setActivities({...newActivitiesToSave, isLoading: false, data: activitiesList});
        } catch (error) {
            alert(_t("messages.fail_fetch_activities"))
            console.error("Error getting activities", error);
        }

        hideSpinner();
    }


    if (book === null) return null;
    if (book === undefined) return <h2>Book not found</h2>
    
    return (
        <PageContainer>
            {true 
                ? (
                    <CoverContainer style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 16px',
                    }}>
                        <PageHeader>
                            <PageTitle style={{fontSize: '1.5rem'}}>{book.title}</PageTitle>
                        </PageHeader>
                        {showGeneratorFrontPage 
                            ? (
                                <PageContent style={{textAlign: 'center', overflow: 'hidden', padding: '16px 0'}}>
                                    <img 
                                        src={book?.image ? book.image : '/images/logo-mhe-2.png'} 
                                        alt='portada del generador de exámenes'
                                        onError={(e) => { e.target.onError = null; e.target.src = '/images/logo-mhe-2.png' }}
                                    />

                                </PageContent>
                            ) : (
                                <PageContent>
                                    <ExamForm
                                            isbn={book.isbn}
                                            hash={book.hash}
                                            language_default={book?.language_default}
                                            units={units}
                                            setSelectedUnits={setSelectedUnits}
                                            totalActivities={activities?.total ?? 0}
                                            generatedExam={generatedExam}
                                            setGeneratedExam={setGeneratedExam}
                                            selectedUnits={selectedUnits}
                                            setLoadActivities={setLoadActivities}
                                            setShowGeneratorFrontPage={setShowGeneratorFrontPage}
                                            setSelectedActivityTypes={setSelectedActivityTypes}
                                        />
                                        <ActivitiesList
                                            activities={activities}
                                            setActivities={setActivities}
                                        />
                                </PageContent>
                            )}
                        {showGeneratorFrontPage && (
                            <PageFooter style={{flexDirection: 'column'}}>
                                <XButton onClick={() => setShowGeneratorFrontPage(false)}>
                                    {_t("buttons.enter_generator")}
                                </XButton>
                            </PageFooter>
                        )}
                    </CoverContainer> 
                ) : (
                    <CoverContainer style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 16px',
                        gap: 20
                    }}>
                        <h2>{book.title}</h2>
                        
                    </CoverContainer>
                )
            }
        </PageContainer>
    )
}

export default GeneratorView;
