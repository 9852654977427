import React, { useEffect } from 'react'
import { StyledDiv } from './StyledMessageToUser'

function MessageToUser({messageToUser, setMessageToUser, ...props}) {
    useEffect(() => {
        if (messageToUser) removeMessage()
    }, [messageToUser])

    const removeMessage = () => {
        setTimeout(() => {
            setMessageToUser('')
        }, 3000)
    }

    if (!messageToUser) return 

    return (
        <StyledDiv>
            <p>
                {messageToUser}
            </p>
        </StyledDiv>
    )
}

export default MessageToUser
