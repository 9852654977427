import React, { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AUTH_USERS, EMAIL_REGEX } from '../../CONSTANTS'
import { XInput } from '@ximdex/xui-react/material'
import { StyledForm, StyledSubmitButton } from './StyledLogin'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { PageHeader, PageTitle } from "../../styled-components/Titles"
import { PageBox, PageContainer } from "../../styled-components"

function Login() {
    const navigation = useNavigate()
    const [_t] = useTranslation("loginPage")

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState('')

    const enterEmail = (currentEmail) => {
        setEmail(currentEmail)
        if (error) setError('')
    }

    const enterPassword = (currentPassword) => {
        setPassword(currentPassword)
        if (error) setError('')
    }

    const checkEmailValidity = (currentEmail) => {
		const isEmailValid = EMAIL_REGEX.test(currentEmail)
		if (!isEmailValid && currentEmail) {
            setError(_t("messages.fail_email"))
        } else if (!currentEmail) {
            setError('')
        }
	}

    const handleToggleShowPassword = () => setShowPassword((prevState) => !prevState)

    const handleEnter = (e) => {
		if (e.key === "Enter") {
			submitForm()
		}
	}


    const checkLoginCredentials = () => {
        return AUTH_USERS.some(checkUser => {
            return checkUser == `${email},${password}`
         })
     }

    const submitForm = (e) => {
        // e.preventDefault()
        if (checkLoginCredentials()) {
            sessionStorage.setItem('isAuth', true)
            navigation('/list-generators')
        } else {
            setError(_t("messages.fail_credentials"))

        } 
    }

    return (
        <PageContainer>
            <PageBox style={{maxWidth: '50%'}}>
                <PageHeader>
                    <PageTitle style={{fontSize: '2.1rem'}}>{_t("title")}</PageTitle>
                </PageHeader>
                <StyledForm id='loginFormExamGenerator' name='loginFormExamGenerator' onSubmit={(e) => submitForm(e)}>
                    <XInput 
                        fullWidth
                        size='small'
                        type='email'
                        name='exam-generator-username'
                        autoComplete='true'
                        value={email} 
                        label={_t("inputs.email")} 
                        onChange={(e) => enterEmail(e.target.value)} 
                        onBlur={(e) => checkEmailValidity(e.target.value)}
                        onKeyPress={(e) => handleEnter(e)}
                    />
                    <XInput 
                        fullWidth
                        size='small'
                        type={showPassword ? 'text' : 'password'}
                        name='exam-generator-password'
                        autoComplete='false'
                        value={password} 
                        label={_t("inputs.password")}  
                        onChange={(e) => enterPassword(e.target.value)} 
                        onKeyPress={(e) => handleEnter(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleToggleShowPassword}
                                    >
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} 
                    />
                    <p style={{ color: 'red', margin: '0 auto', height: '1em' }}>{error}</p>
                    <StyledSubmitButton
                        fullWidth
                        variant='contained'
                        type='submit'
                        form='loginFormExamGenerator'
                        disabled={error ? true : false}
                        onClick={() => submitForm()}
                    >
                        {_t("buttons.login").toUpperCase()}
                    </StyledSubmitButton>
                </StyledForm>
            </PageBox>
            <div style={{ position: "absolute", bottom: "8px", right: "8px" }}>
                <a target="_blank" href="https://www.ximdex.com/" rel="noopener noreferrer">
                    <img
                        src="/images/logotipo_ximdex-poweredby.png" alt="powered by ximdex"
                        style={{ display: "block", maxWidth: "175px", margin: "0 auto", cursor: "pointer" }}
                    />
                </a>
                <p style={{fontSize: '10px', margin: '0'}}>version {process.env.REACT_APP_VERSION}
                    {/* if we are not in the production environment it will show a small text in red */}
                    {!process.env.REACT_APP_XEVAL_URI.startsWith('https://xeval2-backend.mhe.ximdex.net') &&
                        <span style={{ color: 'red', fontWeight: 'bold' }}> PRE</span>
                    }
                </p>
          </div>
        </PageContainer>
    )
}

export default Login
