import { useEffect, useState } from "react";
import ExamContext from "./ExamContext";
import { useSpinner } from "@ximdex/xui-react/hooks";

export function ExamProvider({children}) {
    const [exam, setExam] = useState(null)
    const [units, setUnits] = useState({})
    const [unitsSelected, setUnitsSelected] = useState([])
    const [activities, setActivities] = useState([])
    
    const { showSpinner, hideSpinner } = useSpinner();

    const handleReset = () => {
        setExam(null)
        setUnits({})
    }

    const handleAddUnit = (unit, value) => {
        setUnits({...units, [unit]: value})
    }

    const selectUnits = (values) => {
        if (!Array.isArray(values)) values = [values]
        setUnitsSelected(values)
    }

    useEffect(() => {
        let newActivities = []
        let activitiesToGet = []
        unitsSelected.forEach(unit => {
            if (!units[unit]) {
                activitiesToGet.push(unit)
            } else {
                newActivities.push(units[unitsSelected])
            }
        })
        
        setActivities(newActivities)
    }, [unitsSelected, units])
    

    return (
        <ExamContext.Provider value={{
            exam,
            units,
            units_selected: unitsSelected,
            activities,
            reset: handleReset,
            addUnit: handleAddUnit,
            selectUnits
        }}>
            {children}
        </ExamContext.Provider>
    )
}
